import React from 'react';
import { Fancybox } from '@fancyapps/ui';
import ImageLoader from '@@components/ImageLoader/ImageLoader';
import FullScreenButton from '@@components/FullScreenButton/FullScreenButton';

type TFeaturedImage = {
    readonly src: string;
    readonly alt: string;
};

const FeaturedImage: React.FC<TFeaturedImage> = ({ src, alt }) => {
    const createFancyGallery = (): void => {
        new Fancybox([
            {
                src: src,
                caption: alt,
            },
        ]);
    };

    return (
        <div className='featured-image-container position-relative d-flex justify-content-center mx-auto'>
            <ImageLoader className='featured-image w-100' src={src} alt={alt} minHeight={342} />
            <FullScreenButton onClick={createFancyGallery} />
        </div>
    );
};

export { TFeaturedImage };
export default FeaturedImage;
