import React from 'react';
import parse from 'html-react-parser';
import ImageGallery from '@@components/ImageGallery/ImageGallery';
import { Accordion } from 'react-bootstrap';
import { isImage, isMedia, TAssetId, TSuppContent } from '@@config/wcs';

type TSupplementalsAccordionProps = {
    supplementalContent: TSuppContent[];
};

const SupplementalsAccordion: React.FC<TSupplementalsAccordionProps> = ({ supplementalContent }) => {
    return (
        <>
            <p className='title'>More Information Listed in the Report</p>
            <Accordion className='mr-3'>
                {supplementalContent.map((content, index) => (
                    <Accordion.Item eventKey={`${index}`} key={`${content?.['ASSET_ID'].CID}`}>
                        <Accordion.Header as='h5' className='w-100 d-flex justify-content-between'>
                            <span>{parse(content.TITLE)}</span>
                            <span></span>
                        </Accordion.Header>

                        <Accordion.Body className='px-5 py-3'>
                            {content?.['BODY_JSON'].map(item => {
                                return Object.entries(item).map(([key, value]) => {
                                    if (key === 'TITLE' && typeof value === 'string') {
                                        return <h3 key={key}>{value}</h3>;
                                    }

                                    if (key === 'BODY' && Array.isArray(value)) {
                                        return value.map((paragraph, j) => (
                                            <p key={`${key}-${j}`}>{parse(paragraph)}</p>
                                        ));
                                    }

                                    if (key === 'IMAGE' && isImage(value)) {
                                        return (
                                            <img
                                                key={key}
                                                className='img-fluid mb-2'
                                                src={value['SRC']}
                                                alt={value['ALT_TEXT']}
                                            />
                                        );
                                    }

                                    if (key === 'MEDIA' && isMedia(value)) {
                                        const assetId: TAssetId = value['ASSET_ID'];
                                        return <ImageGallery key={assetId.CID} media={value} />;
                                    }

                                    return null;
                                });
                            })}
                        </Accordion.Body>
                    </Accordion.Item>
                ))}
            </Accordion>
        </>
    );
};

export type { TSupplementalsAccordionProps };
export default SupplementalsAccordion;
