import React, { useContext } from 'react';
import { Fancybox } from '@fancyapps/ui';
import { LocaleContext, TLocaleContext } from '@@contexts/LocaleContext';
import { TImage, TMedia } from '@@config/wcs';
import i18n from './i18n.json';

type TImageGalleryProps = {
    media: TMedia;
};

const ImageGallery: React.FC<TImageGalleryProps> = ({ media }) => {
    const { locale }: TLocaleContext = useContext(LocaleContext);
    const { ASSET_ID: aid, PRIMARY_IMAGE, GALLERY_IMAGES } = media;

    const createFancyGallery = (): void => {
        const fancyGallerySlides = GALLERY_IMAGES.map((image: TImage) => {
            return { src: image.SRC, caption: image.CAPTION ?? image.ALT_TEXT };
        });

        new Fancybox(fancyGallerySlides, { hideScrollbar: true });
    };

    return (
        <div className='py-3 position-relative'>
            <div className='galleryModule' data-asset={`[${aid.C}:${aid.CID}]`}>
                <button className='btn btn-secondary center-block' onClick={createFancyGallery}>
                    {i18n['viewGallery'][locale] ?? 'View Gallery'}
                </button>
                <img className='img-fluid' src={PRIMARY_IMAGE.SRC} alt={PRIMARY_IMAGE.ALT_TEXT} />
            </div>
        </div>
    );
};

export default ImageGallery;
