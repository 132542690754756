import React, { useContext, useState, useEffect, useRef } from 'react';
import { Link } from 'react-router';
import { LocaleConfigs, Locale, TLocale } from '@@config/locale/';
import { LocaleContext, TLocaleContext } from '@@contexts/LocaleContext';
import { getBaseURL } from '@@utils/utils';

const LocaleSelector: React.FC = () => {
    const { locale }: TLocaleContext = useContext(LocaleContext);
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);

    // function changeLocale(L: TLocale) {
    //     updateLocale(L);
    //     setIsOpen(false);
    // }

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className='language-select input-group justify-content-center dropup'>
            {/* https://getbootstrap.com/docs/4.6/components/dropdowns/#single-button */}
            <div className='dropdown' ref={dropdownRef}>
                <button
                    id='dropdownLocaleSelector'
                    className='dropdown-toggle px-3'
                    type='button'
                    data-toggle='dropdown'
                    aria-haspopup='true'
                    aria-expanded={isOpen ? 'true' : 'false'}
                    onClick={() => setIsOpen(!isOpen)}
                >
                    <img
                        src={`${getBaseURL()}/assets/img/global-footer/language-icon.svg`}
                        title='Change Language icon'
                        alt='Change Language icon'
                    />
                    <span className='px-2'>Change Language: {LocaleConfigs[locale]?.userFriendly}</span>
                </button>
                <div className={`dropdown-menu${isOpen ? ' show' : ''}`} aria-labelledby='dropdownLocaleSelector'>
                    {Object.values(Locale).map((L: TLocale) => (
                        <Link
                            key={L}
                            className={`language-select-url dropdown-item${L === locale ? ' active' : ''}`}
                            data-dimension={L}
                            to={`report-check-landing?locale=${L}`}
                            reloadDocument={true}
                        >
                            {LocaleConfigs[L].userFriendly}
                        </Link>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default LocaleSelector;
