import React, { useRef, useEffect, ReactNode } from 'react';
import { Fancybox as NativeFancybox } from '@fancyapps/ui';
// import '@fancyapps/ui/dist/fancybox/fancybox.css';

type FancyboxProps = {
    readonly delegate?: string;
    readonly options?: object;
    readonly children: ReactNode;
};

function Fancybox({ delegate, options, children }: FancyboxProps) {
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const container = containerRef.current;

        const delegateValue = delegate ?? '[data-fancybox]';
        const optionsValue = options ?? {};

        if (container) {
            NativeFancybox.bind(container, delegateValue, optionsValue);
        }

        return () => {
            if (container) {
                NativeFancybox.unbind(container);
                NativeFancybox.close();
            }
        };
    }, [delegate, options]);

    return <div ref={containerRef}>{children}</div>;
}

export default Fancybox;
