import React from 'react';
import parse from 'html-react-parser';

type TAGSGemAttributeCardProps = {
    id: string;
    title: string;
    description: string;
};

const AGSGemAttributeCard: React.FC<TAGSGemAttributeCardProps> = ({ id, title, description }) => {
    return (
        <div className='card'>
            <div className='card-body'>
                <h5 id={id} className='card-title'>
                    {title}
                </h5>
                <p className='card-subtitle text-muted'>{parse(description)}</p>
            </div>
        </div>
    );
};

export type { TAGSGemAttributeCardProps };
export default AGSGemAttributeCard;
