import parse from 'html-react-parser';
import React, { useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getReportCheckErrorMessageURL, TErrorCode } from '@@utils/utils';
import { TLocale } from '@@config/locale/';
import { LocaleContext, TLocaleContext } from '@@contexts/LocaleContext';

type TDataErrorProps = {
    errorCode: TErrorCode;
    fallbackMessage: string;
};

const getReportCheckErrorMessage = async (errorCode: TErrorCode, locale: TLocale) => {
    const url: URL = getReportCheckErrorMessageURL(errorCode, locale);
    const request = new Request(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
        },
    });
    const response = await fetch(request);

    if (!response.ok) {
        throw new Error('Failed to fetch error message');
    }

    return response.json();
};

const DataError: React.FC<TDataErrorProps> = ({ errorCode, fallbackMessage = '' }) => {
    const { locale }: TLocaleContext = useContext(LocaleContext);

    const { data, isLoading, error } = useQuery({
        queryKey: ['errorMessage', errorCode, locale],
        queryFn: () => getReportCheckErrorMessage(errorCode, locale),
    });

    if (error) {
        console.error(error);
        return (
            <div className='container my-5'>
                <div className='alert alert-danger' role='alert'>
                    {fallbackMessage}
                </div>
            </div>
        );
    }

    if (isLoading) {
        return <div className='container my-5 skeleton' style={{ minHeight: '14rem' }}></div>;
    }

    return (
        <div className='container my-5'>
            <h1 className='h2 text-left bg-bottom pb-0'>{data?.TITLE}</h1>
            <strong>
                <p>{parse(data?.BODY)}</p>
            </strong>
        </div>
    );
};

export type { TErrorCode };
export default DataError;
