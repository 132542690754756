import React, { useState, useCallback, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import SingleTooltip from '@@components/SingleTooltip/SingleTooltip';

type TAGSReportDataGroup = {
    label: string;
    value: string;
    tooltip: string;
};

const AGSReportDataGroup: React.FC<TAGSReportDataGroup> = ({ label, value, tooltip }) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const tooltipId = useMemo(() => uuidv4(), []);
    const handleMouseEnter = useCallback(() => setShowTooltip(true), []);
    const handleMouseLeave = useCallback(() => setShowTooltip(false), []);

    return (
        <div className='report-data-row'>
            <dt className='position-relative'>
                <span
                    className='single-tooltip-label'
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    aria-describedby={tooltipId}
                    role='button'
                    tabIndex={-1}
                >
                    {label}
                </span>
                {showTooltip && (
                    <SingleTooltip
                        tooltipId={tooltipId}
                        tooltipContent={tooltip}
                        handleMouseEnter={handleMouseEnter}
                        handleMouseLeave={handleMouseLeave}
                    />
                )}
            </dt>
            <hr className='dotted-separator w-100 my-0' />
            <dd>{value}</dd>
        </div>
    );
};

export type { TAGSReportDataGroup };
export default AGSReportDataGroup;
