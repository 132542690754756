import React from 'react';

const LandingSkeleton: React.FC = () => (
    <div id='main-container' className=''>
        <div className='main-skeleton cloak'>
            <div className='hero-skel'>
                <div className='skeleton preheader-skel'></div>
                <div className='skeleton headline-skel'></div>
                <div className='skeleton lead-skel'></div>
            </div>
            <div className='skeleton content-skel'>
                <div className='skeleton content-search-skel'></div>
            </div>
        </div>
    </div>
);

export default LandingSkeleton;
