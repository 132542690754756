import React from 'react';
import { getBaseURL } from '@@utils/utils';
import { TCharacteristicsInclusion } from '@@config/characteristicsInclusions';

type TKeyToSymbol = {
    readonly imageName: string;
    readonly label: TCharacteristicsInclusion;
};

const KeyToSymbol: React.FC<TKeyToSymbol> = ({ imageName, label }) => {
    return (
        <div className='d-flex justify-content-start align-items-center'>
            <img
                width={15}
                height={15}
                src={`${getBaseURL()}/partials/report-check/img/InclusionImages/${imageName}.jpg`}
                alt={label}
            />
            <span>{label}</span>
        </div>
    );
};

export type { TKeyToSymbol };
export default KeyToSymbol;
