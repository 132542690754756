import { LoaderFunction } from 'react-router';
import { TLocale } from '@@config/locale';
import { getAWSEnv, getCookie, getRdwbApiHost } from '@@utils/utils'; // Adjust import path as needed

export const reactResultsLoader: LoaderFunction = async ({ request }): Promise<Response> => {
    const url: URL = new URL(request.url);
    const reportno = url.searchParams.get('reportno');
    const qr = url.searchParams.get('qr') === 'true';
    const locale = url.searchParams.get('locale') ?? ('en_US' as TLocale);

    const AWS_ENV = getAWSEnv();
    const RDWB_API_HOST = getRdwbApiHost();

    const rdwbToken = await getCookie('rdwb-token');

    if (!rdwbToken && AWS_ENV !== 'local') {
        console.warn('[RDWB] Authorization Token Not Found');
        return Response.json({
            error: { code: '401', message: 'Temporary Authorization Token not found.' },
            reportno: reportno,
        });
    }

    const rdwbTokenEncoded = rdwbToken.replace('/*', '%2f*');
    const rdwbEndpoint: URL = new URL(
        `${RDWB_API_HOST}?reportno=${reportno}&locale=${locale}&env=${AWS_ENV}&USEREG=1&qr=${qr}`
    );

    try {
        const response = await fetch(rdwbEndpoint.toString(), {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Cache-Control': 'no-cache',
                'Rdwb-Token': rdwbTokenEncoded,
            },
            cache: 'no-cache',
        });
        console.debug('[RDWB] response?.ok: 👌');

        if (!response.ok) {
            throw new Error(`HTTP Error >> Status: ${response.status}`);
        }

        const data: any = await response.json();
        console.debug('[RDWB] data: ', data);

        if ('ERROR_CODE' in data) {
            return Response.json({ error: { code: data.ERROR_CODE, message: 'An error occurred' } });
        }

        return Response.json({ data, locale });
    } catch (error) {
        console.error('Loader error:', error);
        return Response.json({ error: { code: '500', message: 'Internal server error' } });
    }
};
