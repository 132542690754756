import React from 'react';
import TableHeader from '@@components/TableHeader/TableHeader';

type TTableProps = {
    title: string;
    children: React.ReactNode;
};

const Table: React.FC<TTableProps> = ({ title, children }) => {
    return (
        <table className='table table-striped mb-4'>
            <TableHeader title={title} />
            <tbody>{children}</tbody>
        </table>
    );
};

export default Table;
