import React from 'react';
import Tooltip from '@@components/Tooltip/Tooltip';

type TGridTableRowData = {
    label: string;
    value: string;
    tooltip: string;
};

type TGridTableRowProps = {
    row: TGridTableRowData;
    index: number;
    currentTooltipIndex: number;
    totalRows: number;
    numCols: number;
    onNextTooltipClick: () => void;
    resetTooltips: () => void;
    showTooltipByIndex: (index: number) => void;
};

const GridTableRow: React.FC<TGridTableRowProps> = ({
    row,
    index,
    currentTooltipIndex,
    totalRows,
    numCols,
    onNextTooltipClick,
    resetTooltips,
    showTooltipByIndex,
}) => {
    return (
        <div className='xtable-row' style={{ gridTemplateColumns: `repeat(${numCols}, 1fr)` }}>
            <div className='xtable-cell position-relative'>
                <span>{row.label}</span>
                {index === currentTooltipIndex && (
                    <Tooltip
                        title={row.label}
                        content={row.tooltip}
                        tooltipIndex={index}
                        totalSlides={totalRows}
                        onNextClick={onNextTooltipClick}
                        resetTooltips={resetTooltips}
                        showTooltipByIndex={showTooltipByIndex}
                    />
                )}
            </div>
            <div className='xtable-cell position-relative d-flex flex-column'>
                <span>{row.value}</span>
            </div>
        </div>
    );
};

export type { TGridTableRowData };
export default GridTableRow;
