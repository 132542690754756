import React, { useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import Card from '@@components/Card/Card';
import { TLocale } from '@@config/locale/';
import { TCard } from '@@config/wcs/';
import { LocaleContext, TLocaleContext } from '@@contexts/LocaleContext';
import { getReportCheckRelatedJSONURL } from '@@utils/utils';

type TRelated = {
    RELATED_TITLE: string;
    CARDS: TCard[];
};

const fetchRelatedContent = async (locale: TLocale): Promise<TRelated> => {
    const url: URL = getReportCheckRelatedJSONURL(locale);
    const request = new Request(url, {
        method: 'GET',
        headers: {
            Accept: 'text/html',
        },
    });    
    const response = await fetch(request);

    if (!response.ok) {
        throw new Error('Failed to fetch related content');
    }

    return response.json();
};

const Related: React.FC = () => {
    const { locale }: TLocaleContext = useContext(LocaleContext);

    const {
        data: content,
        isPending,
        error,
    } = useQuery<TRelated>({
        queryKey: ['relatedContent', locale],
        queryFn: () => fetchRelatedContent(locale),
        staleTime: 60 * 60 * 1000, // 60 minutes
    });

    if (error) {
        console.error(error);
        return <div className='d-none'>Error loading related content</div>;
    }

    return (
        <>
            {!isPending ? (
                <section className='related-content-cards'>
                    <div className='row'>
                        <div className='col'>
                            <h3>{content?.RELATED_TITLE}</h3>
                        </div>
                    </div>
                    {content?.CARDS && (
                        <div className='row row-cols-1 row-cols-sm-2 row-cols-md-4'>
                            {content.CARDS.map((card: TCard) => (
                                <Card data={card} key={card.ASSET_ID.CID} />
                            ))}
                        </div>
                    )}
                </section>
            ) : (
                <div className='skeleton mt-4' style={{ minHeight: '12rem' }}></div>
            )}
        </>
    );
};

export type { TRelated };
export default Related;
