import React, { MouseEventHandler } from 'react';

type TFullScreenButtonProps = {
    readonly onClick: MouseEventHandler<HTMLButtonElement>;
};

const FullScreenButton: React.FC<TFullScreenButtonProps> = ({ onClick }) => {
    return (
        <button
            className='btn full-screen-btn d-flex justify-content-center align-items-center position-absolute p-0'
            aria-label='Full screen'
            onClick={onClick}
        ></button>
    );
};

export type { TFullScreenButtonProps };
export default FullScreenButton;
