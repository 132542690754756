import React, { useContext } from 'react';
import NotificationCarouselElement from '@@elements/NotificationCarousel/NotificationCarousel';
import { LocaleContext, TLocaleContext } from '@@contexts/LocaleContext';

/** Registers the custom element */
customElements.define('notification-carousel', NotificationCarouselElement);

const NotificationCarousel: React.FC = () => {
    const { locale }: TLocaleContext = useContext(LocaleContext);

    return (
        <notification-carousel
            data-output='corporate'
            data-locale={locale}
            data-api-host='notification.gia.edu'
            read-more='Learn more'
            pause='5000'
            animation='750'
            autoplay='true'
            primary-color='#fff'
            background-color='#000'
        ></notification-carousel>
    );
};

export default NotificationCarousel;
