/**
 * An enumeration of the standard report type codes available via the RR API.
 *
 * @enum
 */
export enum ReportTypeCodes {
    AL = 'Alexandrite Report',
    ALO = 'Alexandrite Origin Report',
    ALOTR = 'Alexandrite Origin & Traceability Report',
    CCR = "Cat's Eye Chrysoberyl Report",
    CD = 'Colored Diamond Grading Report',
    CDOR = 'Colored Diamond Origin Report',
    CI = 'Colored Diamond Identification and Origin Report',
    CPC = 'Cultured Pearl Classification Report',
    DD = 'Diamond Dossier Report',
    DDO = 'Diamond Dossier with Origin Report',
    DER = 'Diamond eReport, Diamond e-Report',
    DG = 'Diamond Grading Report',
    DOR = 'Diamond Grading with Origin Report',
    EO = 'Emerald Origin Report',
    EOTR = 'Emerald Origin & Traceability Report',
    ER = 'Emerald Report',
    FC = 'Diamond Focus Report',
    ID = 'Identification Report',
    IDTO = 'Identification & Origin Report, Identification Origin Report',
    IDTT = 'Identification Origin & Traceability Report',
    JG = 'Jewelery Grading Report',
    JR = 'Jade Report',
    LGCD = 'Laboratory-Grown Colored Diamond Grading Report, Laboratory-Grown Colored Diamond Report',
    LGCI = 'Laboratory-Grown Colored Diamond Report - Color Identification',
    LGDG = 'Laboratory-Grown Diamond Grading Report, Laboratory-Grown Diamond Report',
    LGDOSS = 'Laboratory-Grown Diamond Report - Dossier',
    MLE = 'Melee Analysis Service',
    PG = 'Pearl Identification and Classification Report',
    PI = 'Pearl Identification Report, Pearl Classification Report',
    RG = 'Ruby Report',
    RO = 'Ruby Origin Report',
    ROTR = 'Ruby Origin & Traceability Report',
    SCD = 'Synthetic Colored Diamond Grading Report',
    SCI = 'Synthetic Diamond Identification Report',
    SDG = 'Synthetic Diamond Grading Report',
    SG = 'Sapphire Report',
    SO = 'Sapphire Origin Report',
    SOTR = 'Sapphire Origin & Traceability Report',
    SPOR = 'Sapphire Origin Report',
    SPOTR = 'Sapphire Origin & Traceability Report',
    SPR = 'Spinel Report',
    TOR = 'Tourmaline Origin Report',
    TOTR = 'Tourmaline Origin & Traceability Report',
    TR = 'Tourmaline Report',
}

/**
 * An enumeration of report type codes exported as constant readonly strings
 *
 * @const
 * @enum
 */
export const ReportTypeCode = {
    ...ReportTypeCodes,
} as const;

/**
 * A string literal type representing the keys of the `ReportTypeCode` enum.
 *
 * @type
 */
export type TReportTypeCode = keyof typeof ReportTypeCodes;
