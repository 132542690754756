import parse, { Element, HTMLReactParserOptions } from 'html-react-parser';
import React, { useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import LandingSkeleton from '@@components/Landing/LandingSkeleton';
import LookupForm from '@@components/LookupForm/LookupForm';
import { TLocale } from '@@config/locale/';
import { LocaleContext, TLocaleContext } from '@@contexts/LocaleContext';
import { getReportCheckLandingContentURL } from '@@utils/utils';

const fetchLandingContent = async (locale: TLocale) => {
    const url: URL = getReportCheckLandingContentURL(locale);
    const request = new Request(url, {
        method: 'GET',
        headers: {
            Accept: 'text/html',
        },
    });
    const response = await fetch(request);

    if (!response.ok) {
        throw new Error('Failed to fetch Landing content');
    }

    return response.text();
};

const Landing: React.FC = () => {
    const { locale }: TLocaleContext = useContext(LocaleContext);

    // Get the report number from the URL query string or default to empty string
    const reportNo = new URLSearchParams(window.location.search).get('reportno') ?? '';

    const {
        data: content,
        isPending,
        error,
    } = useQuery({
        queryKey: ['landingContent', locale],
        queryFn: () => fetchLandingContent(locale),
        staleTime: 60 * 60 * 1000, // 1 hour
    });

    const options: HTMLReactParserOptions = {
        replace(domNode) {
            if ((domNode as Element).attribs && (domNode as Element).attribs['id'] === 'xreport-lookup-form') {
                return <LookupForm reportNo={reportNo} locale={locale} />;
            }
            return domNode;
        },
    };

    if (isPending) {
        return <LandingSkeleton />;
    }

    if (error) {
        console.error(error);
        return <div>Error loading content</div>;
    }

    return (
        <div id='main-container' style={{ minHeight: 'auto' }}>
            {content && parse(content, options)}
        </div>
    );
};

export default Landing;
