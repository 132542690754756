import React, { KeyboardEvent, useEffect, useRef } from 'react';
import { parseHTML } from '@@utils/utils.ts';

type TTooltipProps = {
    title: string;
    content: string;
    tooltipIndex: number;
    totalSlides: number;
    onNextClick: () => void;
    resetTooltips: () => void;
    showTooltipByIndex: (index: number) => void;
};

const Tooltip: React.FC<TTooltipProps> = ({
    title,
    content,
    tooltipIndex,
    totalSlides,
    onNextClick,
    resetTooltips,
    showTooltipByIndex,
}) => {
    const tabListRef = useRef<HTMLDivElement>(null);

    const nextButtonRef = useRef<HTMLButtonElement>(null);

    const closeButtonRef = useRef<HTMLButtonElement>(null);

    const isFirstTooltip: boolean = tooltipIndex === 0;

    const isLastTooltip: boolean = tooltipIndex === totalSlides - 1;

    const threeTabDots: boolean = totalSlides >= 3;

    useEffect(() => {
        nextButtonRef.current?.focus();
    }, []);

    function handleTabButtonKeyDown(event: KeyboardEvent<HTMLButtonElement>): void | boolean {
        const thisTabBtn: HTMLButtonElement = event.target as HTMLButtonElement;

        // SHIFT + TAB >> Move focus to the tooltip close button
        if (event.shiftKey && event.key === 'Tab') {
            event.preventDefault();
            closeButtonRef.current?.focus();
        } else if (event.key === 'Tab') {
            event.preventDefault();
            /* TAB >> Move focus to the "NEXT" button */
            nextButtonRef.current?.focus();
        }

        // LEFT ARROW >> Move focus to the previous tab button
        if (event.key === 'ArrowLeft') {
            const previousTabButton = thisTabBtn.previousElementSibling as HTMLButtonElement;
            if (previousTabButton) {
                previousTabButton.focus();
            }
        }

        // RIGHT ARROW >> Move focus to the next tab button
        if (event.key === 'ArrowRight') {
            const nextTabButton = thisTabBtn.nextElementSibling as HTMLButtonElement;
            if (nextTabButton) {
                nextTabButton.focus();
            }
        }
    }

    function handleCloseKeyDown(event: KeyboardEvent<HTMLButtonElement>): void {
        // SHIFT + TAB >> Move focus to the "NEXT" button
        if (event.shiftKey && event.key === 'Tab') {
            event.preventDefault();
            nextButtonRef.current?.focus();
        } else if (event.key === 'Tab') {
            event.preventDefault();
            /* TAB >> Move focus to the active tab button within the tablist */
            const tabButton: HTMLButtonElement = tabListRef.current?.querySelector(
                'button[aria-selected="true"]'
            ) as HTMLButtonElement;
            if (tabButton) {
                tabButton.focus();
            }
        }
    }

    return (
        <div id={`panel-${tooltipIndex}`} role='tabpanel' className='xtooltip' aria-labelledby={`tab-${tooltipIndex}`}>
            <div className='xtooltip-content d-flex flex-column w-100'>
                <span className='font-weight-bold'>{title}</span>
                <span className='py-2'>{parseHTML(content)}</span>
            </div>
            <div className='d-flex justify-content-between align-items-end'>
                <div
                    ref={tabListRef}
                    role='tablist'
                    className='xtooltip-track d-flex justify-content-center mb-0 w-100'
                >
                    {threeTabDots && isLastTooltip && (
                        <button
                            role='tab'
                            aria-selected='false'
                            className='border-0 p-0'
                            onKeyDown={handleTabButtonKeyDown}
                            onClick={() => showTooltipByIndex(tooltipIndex - 2)}
                        ></button>
                    )}
                    {!isFirstTooltip && (
                        <button
                            role='tab'
                            aria-selected='false'
                            className='border-0 p-0'
                            onKeyDown={handleTabButtonKeyDown}
                            onClick={() => showTooltipByIndex(tooltipIndex - 1)}
                        ></button>
                    )}
                    <button
                        id={`tab-${tooltipIndex}`}
                        role='tab'
                        aria-selected='true'
                        aria-controls={`panel-${tooltipIndex}`}
                        className='border-0 p-0 current-tab'
                        onKeyDown={handleTabButtonKeyDown}
                    ></button>
                    {!isLastTooltip && (
                        <button
                            role='tab'
                            aria-selected='false'
                            className='border-0 p-0'
                            onKeyDown={handleTabButtonKeyDown}
                            onClick={() => showTooltipByIndex(tooltipIndex + 1)}
                        ></button>
                    )}
                    {threeTabDots && isFirstTooltip && (
                        <button
                            role='tab'
                            aria-selected='false'
                            className='border-0 p-0'
                            onKeyDown={handleTabButtonKeyDown}
                            onClick={() => showTooltipByIndex(tooltipIndex + 2)}
                        ></button>
                    )}
                </div>
                <button
                    className='btn btn-primary'
                    ref={nextButtonRef}
                    onClick={onNextClick}
                    style={{ padding: '0.375rem 0.75rem' }}
                >
                    Next
                </button>
            </div>
            <button
                ref={closeButtonRef}
                className='xtooltip-close position-absolute border-0'
                aria-label='close'
                onKeyDown={handleCloseKeyDown}
                onClick={resetTooltips}
            ></button>
        </div>
    );
};

export type { TTooltipProps };
export default Tooltip;
