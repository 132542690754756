import React, { useState } from 'react';
import { Locales, TLocale } from '@@config/locale';
import { LocaleContext, TLocaleContext } from '@@contexts/LocaleContext';

export const getLocaleFromUrl = (): TLocale => {
    if (typeof window !== 'undefined') {
        const params = new URLSearchParams(window.location.search);
        const localeParam = params.get('locale') as TLocale;

        if (localeParam && localeParam in Locales) {
            return localeParam;
        }
    }

    return Locales.en_US;
};

export const LocaleProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [locale, setLocale] = useState<TLocale>(getLocaleFromUrl());

    const localeContext: TLocaleContext = {
        locale: locale,
        updateLocale: (newLocale: TLocale): void => {
            setLocale(newLocale);
        },
    };

    return <LocaleContext.Provider value={localeContext}>{children}</LocaleContext.Provider>;
};
