import React from 'react';
import { Fancybox } from '@fancyapps/ui';

type TOriginImagesSectionProps = {
    originPolishedImage: string;
    originRoughImage: string;
    disclaimer: string;
    imagesCaption: string;
};

const OriginImagesSection: React.FC<TOriginImagesSectionProps> = ({
    originPolishedImage,
    originRoughImage,
    disclaimer,
    imagesCaption = '',
}) => {
    const handleClick = (event: React.MouseEvent<HTMLAnchorElement>): void => {
        event.preventDefault();
        const target = event.currentTarget as HTMLAnchorElement;
        createFancyGallery(target.getAttribute('data-href') ?? '', target.getAttribute('data-caption') ?? '');
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLAnchorElement>): void => {
        if (event.key === 'Enter' || event.key === ' ') {
            event.preventDefault();
            const target = event.target as HTMLAnchorElement;
            createFancyGallery(target.getAttribute('data-href') ?? '', target.getAttribute('data-caption') ?? '');
        }
    };

    const createFancyGallery = (src: string, alt: string): void => {
        new Fancybox([
            {
                src: src,
                caption: alt,
            },
        ]);
    };

    return (
        <>
            <tr>
                <td id='new-dor-disclaimer' colSpan={2} className='bg-white text-right'>
                    {disclaimer}
                </td>
            </tr>
            <tr>
                <td colSpan={2} className='origin-images bg-white text-right w-100'>
                    <a
                        role='button'
                        onClick={handleClick}
                        onKeyDown={handleKeyDown}
                        tabIndex={0}
                        data-href={originRoughImage}
                        data-caption='Origin rough'
                        data-fancybox
                    >
                        <img src={originRoughImage} id='ORGROU' alt='Origin rough' />
                    </a>

                    <a
                        role='button'
                        onClick={handleClick}
                        onKeyDown={handleKeyDown}
                        tabIndex={0}
                        data-href={originPolishedImage}
                        data-caption='Origin polished'
                        data-fancybox
                    >
                        <img src={originPolishedImage} id='ORGPOL' alt='Origin polished' />
                    </a>
                </td>
            </tr>
            <tr>
                <td colSpan={2} className='bg-white text-right'>
                    {imagesCaption}
                </td>
            </tr>
        </>
    );
};

export default OriginImagesSection;
