import React from 'react';
import parse from 'html-react-parser';

type TTooltipProps = {
    tooltipId: string;
    tooltipContent: string;
    handleMouseEnter: () => void;
    handleMouseLeave: () => void;
};

/**
 * SingleTooltip component implemented in a custom way while adhering to the W3C Tooltip Pattern.
 * @see {@link https://www.w3.org/WAI/ARIA/apg/patterns/tooltip/}
 */
const SingleTooltip: React.FC<TTooltipProps> = ({ tooltipId, tooltipContent, handleMouseEnter, handleMouseLeave }) => {
    return (
        <div
            id={tooltipId}
            className='single-tooltip'
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            aria-hidden={false}
            role='tooltip'
        >
            <div className='single-tooltip-backdrop single-tooltip-backdrop-top'></div>
            <div className='single-tooltip-backdrop single-tooltip-backdrop-bottom'>
                <p className='single-tooltip-content'>{parse(tooltipContent)}</p>
            </div>
        </div>
    );
};

export default SingleTooltip;
