import React from 'react';
import { parseHTML } from '@@utils/utils.ts';
import { TCard } from '@@config/wcs/';

type TCardProps = {
    readonly data: TCard;
};

const InfoCard: React.FC<TCardProps> = ({ data }) => {
    const { DESCRIPTION, IMAGE, TITLE } = data;

    return (
        <div className='col my-3 my-lg-0'>
            <div className='card info-card p-2 p-lg-4 border-0'>
                <div className='d-flex justify-content-center justify-content-sm-start'>
                    {IMAGE && <img className='pb-4' src={IMAGE.SRC} alt={IMAGE.ALT_TEXT} style={{ maxWidth: 32 }} />}
                </div>
                <div className='card-body p-0'>
                    <h5 className='card-title text-center text-sm-left text-uppercase' style={{ fontSize: '1rem' }}>
                        {TITLE}
                    </h5>
                    <p className='card-text text-center text-sm-left'>{parseHTML(DESCRIPTION)}</p>
                </div>
            </div>
        </div>
    );
};

export type { TCardProps };
export default InfoCard;
