import React, { useEffect, useRef, useState, FormEvent } from 'react';
import { Form, useSubmit } from 'react-router';
import { TLabelsDictionary } from '@@config/i18n/';
import { TLocale } from '@@config/locale/';
import { sanitizeReportNumber } from '@@utils/utils';
import i18n from './i18n.json';

type TReportLookupFormProps = {
    readonly reportNo: string;
    readonly locale: TLocale;
};

const LookupForm: React.FC<TReportLookupFormProps> = ({ reportNo, locale }) => {
    const labels: TLabelsDictionary = i18n[locale];
    console.log('labels >> ', labels);

    const [reportNumber, setReportNumber] = useState<string>(reportNo);
    const [validationMessage, setValidationMessage] = useState<string>('');

    const reportInput = useRef<HTMLInputElement>(null);
    const lookupForm = useRef<HTMLFormElement>(null);
    const submitBtn = useRef<HTMLButtonElement>(null);

    const submit = useSubmit();

    useEffect(() => {
        if (reportNumber) {
            reportInput.current?.focus();
        }
    }, []);

    function handleInvalid(event: FormEvent): void {
        const target = event.target as HTMLInputElement;

        if (!target.validity.valid) {
            lookupForm.current?.classList.add('was-validated');
            target.setCustomValidity(
                labels?.['INPUT_INVALID_MESSAGE']?.label ?? 'Please provide a valid report number'
            );
        } else {
            lookupForm.current?.classList.remove('was-validated');
            target.setCustomValidity('');
            setValidationMessage('');
        }

        setValidationMessage(target.validationMessage);
    }

    function handleInput(event: FormEvent): void {
        const target = event.target as HTMLInputElement;
        setReportNumber(target.value);

        target.setCustomValidity('');
        setValidationMessage('');
    }

    function handleSubmit(event: FormEvent): void {
        event.preventDefault();
        const form = event.target as HTMLFormElement;
        const isValid: boolean = form.checkValidity();
        form.classList.add('was-validated');

        if (!isValid) {
            return;
        }

        if (reportInput.current) {
            reportInput.current.setCustomValidity('');
        }

        if (submitBtn.current) {
            submitBtn.current.setAttribute('disabled', 'true');
        }

        submit(
            [
                ['locale', locale],
                ['reportno', sanitizeReportNumber(reportNumber)],
            ],
            { method: 'GET', action: '/report-check' }
        );
    }

    return (
        <Form ref={lookupForm} onSubmit={handleSubmit} className='callout-container needs-validation shadow-sm py-4' style={{borderRadius: 5}}noValidate>
            <div className='form-row align-items-md-center py-2'>
                <label
                    htmlFor='reportno'
                    className='col-12 col-md-4 col-form-label d-flex justify-content-center justify-content-md-end'
                >
                    {labels?.['LOOK_UP_REPORT']?.label ?? 'Look Up a Report'}
                </label>

                <div className='col-12 col-md-4 mb-4 mb-md-0'>
                    <input
                        ref={reportInput}
                        type='text'
                        className='form-control'
                        id='reportno'
                        name='reportno'
                        placeholder={labels?.['ENTER_REPORT_NUMBER']?.label ?? 'Enter Report Number'}
                        pattern='^(\d+-?\d+|[a-zA-Z0-9]{4,5})$'
                        minLength={4}
                        maxLength={14}
                        value={reportNumber}
                        onInvalid={handleInvalid}
                        onInput={handleInput}
                        required
                    />
                    <span className='invalid-feedback position-absolute'>{validationMessage}</span>
                </div>

                <div className='col-12 col-md-4 mt-2 mt-md-0 d-flex justify-content-center justify-content-md-start'>
                    <button ref={submitBtn} type='submit' className='btn search-btn'>
                        {labels?.['LOOK_UP']?.label ?? 'Look Up'}
                    </button>
                </div>
            </div>
        </Form>
    );
};

export default LookupForm;
export type { TReportLookupFormProps };
