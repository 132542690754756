import React from 'react';
import { useRouteError } from 'react-router';

const ErrorPage: React.FC = () => {
    const error = useRouteError();
    console.error(error);

    return (
        <div
            id='error-page'
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
            }}
        >
            <h1 style={{ backgroundPosition: 'center bottom' }}>404</h1>
            <p>Sorry, an unexpected error has occurred.</p>
        </div>
    );
};

export default ErrorPage;
