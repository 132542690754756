import React from 'react';

type TGridTableHeaderProps = {
    readonly title: string;
};

const GridTableHeader: React.FC<TGridTableHeaderProps> = ({ title }) => {
    return <h4 className='xtable-header text-uppercase text-left bg-none'>{title}</h4>;
};

export { TGridTableHeaderProps };
export default GridTableHeader;
