import React, { useState, useCallback, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Fancybox from '@@components/Fancybox/Fancybox';
import KeyToSymbol, { TKeyToSymbol } from '@@components/KeyToSymbol/KeyToSymbol';
import { TTableItemData } from '@@components/GradingReport/GradingReport';
import SingleTooltip from '@@components/SingleTooltip/SingleTooltip';

type TTableRowProps = {
    tableItemKey: string;
    tableItemData: TTableItemData;
    keyToSymbols?: TKeyToSymbol[];
};

const TableRow: React.FC<TTableRowProps> = ({ tableItemKey, tableItemData, keyToSymbols }) => {
    const { KIND, LABEL, TOOLTIP, VALUE } = tableItemData;

    const [showTooltip, setShowTooltip] = useState(false);
    const tooltipId = useMemo(() => uuidv4(), []);

    const handleMouseEnter = useCallback(() => setShowTooltip(true), []);
    const handleMouseLeave = useCallback(() => setShowTooltip(false), []);

    if (KIND === 'image' && tableItemKey === 'PLOTIMG' && keyToSymbols?.length) {
        return (
            <tr className='bg-white'>
                <td className=''>
                    <div className='d-flex flex-column align-items-baseline'>
                        {keyToSymbols.map((kts: TKeyToSymbol, i: number) => (
                            <KeyToSymbol key={`${i}:${kts.imageName}`} imageName={kts.imageName} label={kts.label} />
                        ))}
                    </div>
                </td>
                <td className='text-right'>
                    <Fancybox>
                        <a data-fancybox href={VALUE}>
                            <img className='table-image' id={tableItemKey} src={VALUE} alt={LABEL} />
                        </a>
                    </Fancybox>
                </td>
            </tr>
        );
    }

    if (KIND === 'image') {
        return (
            <tr className='bg-white'>
                <td>&nbsp;</td>
                <td className='text-right'>
                    <Fancybox>
                        <a data-fancybox href={VALUE}>
                            <img className='table-image' id={tableItemKey} src={VALUE} alt={LABEL} />
                        </a>
                    </Fancybox>
                </td>
            </tr>
        );
    }

    return (
        <tr>
            {TOOLTIP && (
                <td className='position-relative'>
                    <span
                        data-test-id={`${tableItemKey}_LABEL`}
                        className='single-tooltip-label'
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        aria-describedby={tooltipId}
                        role='button'
                        tabIndex={-1}
                    >
                        {LABEL}
                    </span>
                    {showTooltip && (
                        <SingleTooltip
                            tooltipId={tooltipId}
                            tooltipContent={TOOLTIP}
                            handleMouseEnter={handleMouseEnter}
                            handleMouseLeave={handleMouseLeave}
                        />
                    )}
                </td>
            )}
            <td colSpan={TOOLTIP ? 1 : 2}>
                <span id={tableItemKey}>{VALUE}</span>
            </td>
        </tr>
    );
};

export default TableRow;
